import { css } from 'styled-components'

export const gray = {
  300: '#D1D4E3',
  400: '#F5F6FA',
  500: '#E6E7F0',
  550: '#DEDFE4',
  600: '#8C91A9',
  650: '#6A6C72',
  700: '#6A6E81',
  750: '#161616',
  800: '#4B4E5C',
  900: '#D7D7D7',
  light: '#747684',
  base: '#58595b',
  medium: '#4B4E5C',
  dark: '#292929',
}

export const white = '#FFFFFF'

export const interAsset = {
  brand: '#172740',
}

export const orange = {
  light: '#FF9F33',
  base: '#EB7000',
  medium: '#FF7A00',
  dark: '#FF500F',
}

export const brown = {
  base: '#A56543',
}

export const green = {
  100: '#849495',
  200: '#CFDBD6',
  300: '#36b29c',
  500: '#20C05D',
}

export const yellow = {
  500: '#FFBA00',
}

export const red = {
  100: '#FFF5F6',
  500: '#FD4F47',
}

export const blue = {
  500: '#194A69',
  700: '#072534',
  900: '#161616',
}

export const linear = {
  orange: 'linear-gradient(45deg, #FF500F 0%, #FF8700 100%)',
}

type TColor = {
  [name: string]: string;
}

export const rechartsStrokeColors = [
  '#039BE5',
  '#C0CA33',
  '#A1887F',
  '#26A69A',
  '#7986CB',
  '#7CB342',
  '#4FC3F7',
  '#DCE775',
  '#BCAAA4',
  '#FFAB91',
  '#B39DDB',
  '#80CBC4',
]

export function generateColorClasses (colorMap: TColor, name: string) {
  return (
    Object.keys(colorMap).map((type: string) => (
      css`
        .bg-${name}-${type} { background: ${colorMap[type]}; }
        .text-${name}-${type}   { color: ${colorMap[type]}; }
        .fill-${name}-${type}   { fill: ${colorMap[type]}; }
      `
    ))
  )
}
